@media screen and (max-width: 1200px) {
	body:not(.rtl) {
		.sidebar-group {
			position: fixed;
			right: -50%;
			top: 0;
			bottom: 0;
			width: 30%;
			opacity: 0;
			transition: all .2s;
			box-shadow: 25px 0 30px 5px black;
			visibility: hidden;
			z-index: 9;

			.sidebar {
				width: 100% !important;
				margin-left: 0 !important;
				margin-right: 0 !important;
				border-radius: 0 !important;
			}

			&.mobile-open {
				opacity: 1;
				right: 0;
				visibility: visible;
			}
		}
	}
	body.rtl {
		.sidebar-group {
			position: fixed;
			left: -50%;
			top: 0;
			bottom: 0;
			opacity: 0;
			transition: all .2s;
			box-shadow: -25px 0px 30px 5px black;
			visibility: hidden;

			.sidebar {
				width: 100% !important;
				margin-left: 0 !important;
				margin-right: 0 !important;
				border-radius: 0 !important;
			}

			&.mobile-open {
				opacity: 1;
				left: 0;
				visibility: visible;
			}
		}

		.chat + .sidebar-group .sidebar {
			margin-right: 0 !important;
		}
	}
	body.modal-open {
		.sidebar-group {
			right: 0;
		}
	}
}

@media screen and (max-width: 992px) {
	body:not(.rtl), body {
		.sidebar-group {
			width: 45%;
		}
	}
	nav.navigation {
		ul {

		}
	}
}

@media screen and (max-width: 768px) {

	.sidebar-group {
		.sidebar {
			.list-group-item {
				.action-toggle {
					.dropdown {
						display: block !important;
					}
				}
			}
		}
	}

	body:not(.rtl), body {
		.sidebar-group {
			width: 75%;

			.sidebar {
				.list-group-item {
					.users-list-body h5 {
						font-size: $default-font-size + 7 !important;
					}
				}
			}
		}
	}

	.layout {
		.content {
			padding: 20px !important;

			.chat {
				.chat-header {
					display: block !important;
					padding-bottom: 15px !important;

					.chat-header-user {
						h5 {
							font-size: $default-font-size + 5 !important;
						}
					}

					.chat-header-action {
						margin-top: 15px;
					}
				}

				.chat-body {
					.messages {
						.message-item {
							max-width: 100% !important;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	body:not(.rtl), body {
		.sidebar-group {
			width: 100%;
		}
	}

	body.form-membership {
		padding: 20px;

		.form-wrapper {
			padding-right: 20px;
			padding-left: 20px;
			width: 100% !important;
			margin-bottom: 20px;
		}
	}
}

