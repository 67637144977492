.dropdown-menu {
	border-color: white - 20;
	box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 20px -10px;
	border-radius: 5px;

	.dropdown-item {
		font-size: $default-font-size;

		&:focus, &:hover, &:active, &.active {
			background-color: white - 20;
			color: black;
		}
	}
}